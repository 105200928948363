import React, { useCallback } from 'react';
import { withRouter, useParams, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import OrganizationForm from 'components/forms/OrganizationForm.js';
import OrganizationSettings from 'components/forms/OrganizationSettings.js';
import useValidation from 'hooks/useValidation.js';
import useSnackbar from 'hooks/useSnackbar.js';
import useOrganization from 'hooks/useOrganization';
import useInvoiceNumberingModal from 'hooks/useInvoiceNumberingModal';
import LastInvoicesPerYear from 'types/LastInvoicesPerYear';
import InvoiceNumberPrefixText from 'types/InvoiceNumberPrefixText';
import Router from 'utils/Router';
import * as Routes from 'constants/Routes.js';
import OrganizationSubscriptionSource from 'types/OrganizationSubscriptionSource';

type OrganizationParams = {
  organizationId: string;
};

const Organization = () => {
  
  const { organizationId } = useParams<OrganizationParams>();

  const { register, errors, handleSubmit } = useForm();
  const validationErrors = useValidation(errors);
  const organization = useOrganization(organizationId);

  const setNextInvoiceNumber = (lastInvoices: LastInvoicesPerYear[], invoiceNumberPrefixTexts: InvoiceNumberPrefixText[]) => {
    organization.organizationData.lastInvoices = lastInvoices;
    organization.setOrganizationData(organization.organizationData);
  };

  const invoiceNumberingModal = useInvoiceNumberingModal(organizationId, null, undefined, setNextInvoiceNumber);
  
  const snackbar = useSnackbar();

  const saveOrganization = useCallback(async (formData) => {
    await organization.save(formData);
    
    snackbar.showSaveSuccess();
  }, [ organization, snackbar ]);

  if (organization.loading) {
    return <></>
  }

  return (
    <div className="page organization">
      <div className="dummy-top hide-on-mobile"></div>
      <h1 className="hide-on-mobile">Nastavitve</h1>
      <form onSubmit={handleSubmit(saveOrganization)}>
        <div className="panel">
          <div className="split-table">
            <OrganizationForm data={organization.organizationData} detailsRequired hasTaxDebtor errors={errors} register={register} hasEmail={undefined} hasGlnCode={undefined} autoFocus={undefined} />
          </div>
          <OrganizationSettings data={organization.organizationData} organizationId={organizationId} errors={errors} register={register} />
          <div className="button-bottom-bar error">
            {validationErrors}
          </div>
          <div className="button-bottom-bar r-align">
            <span className="link action l-float" onClick={invoiceNumberingModal.open}>Urejaj številčenje računov</span>
            <span className="l-float pipe hide-on-mobile">|</span>
            <Link className="link action l-float" to={Router.route(Routes.organizationSubscriptions, { organizationId: organizationId }, { source: OrganizationSubscriptionSource.Settings })}>Podatki o naročnini</Link>
            <button className="buttonish text-only" disabled={organization.saving} type="submit">Shrani</button>
          </div>
        </div>
      </form>
      {invoiceNumberingModal.render()}
      {snackbar.render()}
    </div>
  );
};

export default withRouter(Organization);
