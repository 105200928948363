import JSZip from "jszip";

type FileToZip = {
  blob: Blob;
  fileName: string;
}

class ZipHelper {

  static async createZipFileAsync(files: FileToZip[]) {
    const zip = new JSZip();
      
    files.forEach(file => {
        zip.file(file.fileName, file.blob);
    });

    return zip.generateAsync({ type: "blob" });
  }

}

export default ZipHelper;
