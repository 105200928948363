import React, { useState, useCallback } from 'react';
import { withRouter, Link, useLocation } from 'react-router-dom';
import { useFirebase } from 'config/Firebase';
import TextBox from 'components/forms/TextBox.js';
import LegalFooter from 'components/LegalFooter.js';
import AuthenticationLogo from 'components/AuthenticationLogo.js';
import * as Routes from 'constants/Routes.js';
import Router from 'utils/Router.js';
import Bugsnag from '@bugsnag/js';

const Login = () => {

  const [errorText, setErrorText] = useState('');

  const query = new URLSearchParams(useLocation().search);
  const defaultEmail = query.get('email');
  
  const firebaseContext = useFirebase();

  const [email, setEmail] = useState(defaultEmail ? defaultEmail : '');
  const [password, setPassword] = useState('');
  
  const loginWithEmailAndPassword = useCallback(async event => {
    event.preventDefault();
    
    setErrorText('');
    
    Bugsnag.leaveBreadcrumb('login', { email, password });

    firebaseContext.firebase.signInWithEmailAndPassword(email.trim(), password).then(() => {
      firebaseContext.firebase.logEvent('login');
    }).catch((error) => {
      var errorCode = error.code;
      console.log('Login error: ' + errorCode);

      switch (errorCode) {
        case 'auth/invalid-email':
          setErrorText('Vnesite veljaven e-mail naslov.');
          break;
        case 'auth/wrong-password':
          setErrorText('Napačno geslo.');
          break;
        case 'auth/internal-error':
          setErrorText('Neveljavna kombinacija e-maila in gesla.');
          break;
        case 'auth/user-not-found':
          setErrorText('Uporabnik ne obstaja.');
          break;
        case 'auth/too-many-requests':
          setErrorText('Prekoračili ste dovoljeno število poizkusov. Poskusite znova kasneje.');
          break;
        case 'auth/network-request-failed':
          setErrorText('Preverite vašo internetno povezavo in poskusite ponovno.');
          break;
        default:
          Bugsnag.notify(error, event => {
            event.addMetadata('errorContext', {
              errorCode: errorCode,
              email: email,
              password: password
            });
          });
          setErrorText('Pri prijavi je prišlo do napake: ' + errorCode);
          break;
      }
    });

  }, [firebaseContext.firebase, email, password]);
  
  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <form className="page authentication" onSubmit={loginWithEmailAndPassword}>
      <AuthenticationLogo />
      <h1>Prijava obstoječega uporabnika</h1>
      <div className="content">    
        <TextBox fullLine name="email" type="email" label="E-mail" value={email} onChange={onEmailChange} />
        <TextBox fullLine name="password" type="password" label="Geslo" value={password} onChange={onPasswordChange} />
      </div>
      <div className="bottom-bar">
        <button className="buttonish text-only" type="submit">Prijava</button>
      </div>
      <div className="bottom-bar error">
        {errorText}
      </div>
      <div className="bottom-bar">
        Še niste registrirani? <Link className="nav-item" to={Router.route(Routes.signup, null, { email: email })}>Registrirajte se</Link>
        <div className="line">
          Ste pozabili geslo? <Link className="nav-item" to={Router.route(Routes.resetPassword, null, { email: email })}>Kliknite tukaj</Link>
        </div>
      </div>
      <LegalFooter />
    </form>
  );
};

export default withRouter(Login);
