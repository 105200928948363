import SecondaryButton from "components/SecondaryButton";
import { useFirebase } from "config/Firebase";
import React from "react";

type DownloadInvoiceButtonProps = {
  disabled: boolean;
  onClick: (extension: 'pdf' | 'zip' | 'xml') => {};
};

const DownloadInvoiceButton = (props: DownloadInvoiceButtonProps) => {

  const firebaseContext = useFirebase();
  
  const handlePdfClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    firebaseContext!.firebase.logEvent('download_pdf');

    props.onClick('pdf');
  };

  const handleZipClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    firebaseContext!.firebase.logEvent('download_zip');
    
    props.onClick('zip');
  };

  const handleXmlClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    firebaseContext!.firebase.logEvent('download_xml');
    
    props.onClick('xml');
  };

  const actions = [
    {
      onClick: handlePdfClick,
      text: 'Prenesi kot PDF'
    },
    {
      onClick: handleZipClick,
      text: 'Prenesi kot e-račun (.zip)'
    },
    {
      onClick: handleXmlClick,
      text: 'Prenesi kot e-račun (.xml)'
    }
  ];

  return (
    <>
      <button className="buttonish text-only secondary w-secondary" onClick={handlePdfClick} disabled={props.disabled}>Prenesi</button>
      <SecondaryButton className="secondary" disabled={props.disabled} actions={actions} anchorAtBottom={true} />
    </>
  )

};

export default DownloadInvoiceButton;
