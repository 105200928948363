import { useState } from 'react';

const useInvoiceButtonState = () => {
  
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [createInvoiceButtonDisabled, setCreateInvoiceButtonDisabled] = useState(false);
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);
  const [printPdfButtonDisabled, setPrintPdfButtonDisabled] = useState(false);

  return {
    saveButtonDisabled,
    setSaveButtonDisabled,
    sendButtonDisabled,
    setSendButtonDisabled,
    createInvoiceButtonDisabled,
    setCreateInvoiceButtonDisabled,
    downloadButtonDisabled,
    setDownloadButtonDisabled,
    printPdfButtonDisabled,
    setPrintPdfButtonDisabled
  };

};

export default useInvoiceButtonState;
