import React from 'react';
import TaxNumberHelper from 'utils/TaxNumberHelper.js';

const InvoiceOrganization = ({ name, organizationData, register }) => {

  return (
    <div className="partner-text-box">      
      <div className="details">
        <ul>
          <li>{organizationData.name}</li>
          <li>{organizationData.address}</li>
          <li>{organizationData.postOffice} {organizationData.city}</li>
          <li>{organizationData.country}</li>
          <li>{TaxNumberHelper.getTaxNumberString(organizationData.taxNumber)}</li>
        </ul>
        
        <input name={name + '.name'} defaultValue={organizationData.name} ref={register} type="hidden" />
        <input name={name + '.address'} defaultValue={organizationData.address} ref={register} type="hidden" />
        <input name={name + '.postOffice'} defaultValue={organizationData.postOffice} ref={register} type="hidden" />
        <input name={name + '.city'} defaultValue={organizationData.city} ref={register} type="hidden" />
        <input name={name + '.country'} defaultValue={organizationData.country} ref={register} type="hidden" />
        <input name={name + '.taxNumber'} defaultValue={organizationData.taxNumber} ref={register} type="hidden" />
        <input name={name + '.bankAccount'} defaultValue={organizationData.bankAccount} ref={register} type="hidden" />
        <input name={name + '.accountingEmail'} defaultValue={organizationData.accountingEmail} ref={register} type="hidden" />
        <input name={name + '.email'} defaultValue={organizationData.email} ref={register} type="hidden" />
        <input name={name + '.phoneNumber'} defaultValue={organizationData.phoneNumber} ref={register} type="hidden" />
        <input name={name + '.countryIssuedId'} defaultValue={organizationData.countryIssuedId} ref={register} type="hidden" />
        <input name={name + '.bankBusinessIdentifierCode'} defaultValue={organizationData.bankBusinessIdentifierCode} ref={register} type="hidden" />
      </div>
    </div>
  );
  
};

export default InvoiceOrganization;
