import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import { ReactComponent as DropdownArrow } from 'icons/dropdown-arrow.svg';
import { Link } from "react-router-dom";

type SecondaryButtonAction = {
  to?: string | undefined;
  onClick?: MouseEventHandler<HTMLSpanElement> | undefined;
  text: string;
};

type SecondaryButtonProps = {
  actions: SecondaryButtonAction[];
  disabled?: boolean | undefined;
  className?: string | undefined;
  anchorAtBottom?: boolean | undefined;
};

const SecondaryButton = (props: SecondaryButtonProps) => {
  const showButtonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuVisible, setMenuVisible] = useState(false);
  
  const getSecondaryLink = (action: SecondaryButtonAction) => {
    const key = action.to + action.text;
    if (action.to) {
      return (
        <Link key={key} className="dropdown-item secondary-action" to={action.to}>{action.text}</Link>
      )
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, any>) => {
      setMenuVisible(false);
      
      if (action.onClick) {
        action.onClick(e);
      }
    }

    if (action.onClick) {
      return (
        <button key={key} className="dropdown-item secondary-action link" onClick={handleClick}>{action.text}</button>
      );;
    }

    throw new Error('Invalid secondary action');
  };

  const secondaryLinks = props.actions.map(action => {
    return getSecondaryLink(action);
  });
  
  const className = props.className ? `buttonish secondary-button ${props.className}` : 'buttonish secondary-button';
  const menuClassName = props.anchorAtBottom ? `dropdown-list secondary-menu bottom` : 'dropdown-list secondary-menu';

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // Prevent focus outline around the button to be shown after click.
    showButtonRef.current?.blur();
    
    setMenuVisible(!menuVisible);
  };
  
  useEffect(() => {
    const hideMenu = (e: MouseEvent) => {
      const showButtonClicked = showButtonRef.current && e.target && (showButtonRef.current === e.target || showButtonRef.current.contains(e.target as any));
      const menuClicked = menuRef.current && e.target && (menuRef.current === e.target || menuRef.current.contains(e.target as any));
      if (menuVisible && !showButtonClicked && !menuClicked) {
        setMenuVisible(false);
      }
    }

    document.addEventListener("mousedown", hideMenu);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", hideMenu);
    }
  }, [menuVisible]);

  const menu = menuVisible ? (
    <div className="secondary-menu-holder">
      <div className={menuClassName} ref={menuRef}>
        {secondaryLinks}
      </div>
    </div>
  ) : null;

  return (
    <>
      <button className={className} onClick={toggleMenu} ref={showButtonRef} disabled={props.disabled}>
        <DropdownArrow />
      </button>
      {menu}
    </>
  )
};

export type { SecondaryButtonAction };

export default SecondaryButton;
