import React, { useCallback, useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import OrganizationForm from 'components/forms/OrganizationForm.js';
import * as Routes from 'constants/Routes.js';
import Router from 'utils/Router.js';
import useValidation from 'hooks/useValidation.js';
import useCollection from 'hooks/useCollection.js';
import Bugsnag from '@bugsnag/js';

const Partner = ({history}) => {
  
  const [partnerData, setPartnerData] = useState(null);

  const { register, errors, handleSubmit } = useForm();
  const validationErrors = useValidation(errors);
  
  let { partnerId } = useParams();
  if (partnerId === 'new') {
    partnerId = null;
  }
  const partnersCollection = useCollection('partners');
  
  const savePartner = useCallback((formData) => {
    const data = partnerData ? partnerData : {};
    data.name = formData.name;
    data.address = formData.address;
    data.postOffice = formData.zip;
    data.city = formData.city;
    data.country = formData.country;
    data.taxNumber = formData.taxnumber;
    data.countryIssuedId = formData.countryissuedid;
    data.email = formData.email;
    data.glnCode = formData.glnCode;
    data.bankAccount = formData.bankaccount;
    data.bankBusinessIdentifierCode = formData.bankBusinessIdentifierCode;
    
    const saveAndRedirect = async() => {
      try {
        await partnersCollection.addOrUpdate(partnerId, data);
        const route = Router.route(Routes.partners);
        history.push(route);
      }
      catch (error) {
        Bugsnag.notify(error);
        console.log('Error while saving partner: ' + error);
      }
    };

    saveAndRedirect();
  }, [ partnerId, partnersCollection, partnerData, history ]);
    
  useEffect(() => {
    const getPartner = async () => {
      const data = await partnersCollection.get(partnerId);
      setPartnerData(data);
    };

    if (partnerId && !partnerData) {
      getPartner();
    }
  }, [partnerId, partnerData, partnersCollection]);

  const isFetchingData = !partnerData && partnerId;
  if (isFetchingData) {
    return <></>
  }

  return (
    <div className="page partner">
      <div className="dummy-top"></div>
      <h1>Vnesite podatke o partnerju</h1>
      <form onSubmit={handleSubmit(savePartner)}>
        <div className="panel">
          <div className="split-table">
            <OrganizationForm data={partnerData} hasEmail hasGlnCode errors={errors} register={register} />
          </div>
          <div className="button-bottom-bar error">
            {validationErrors}
          </div>
          <div className="button-bottom-bar r-align">
            <button className="buttonish text-only" type="submit">Shrani</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withRouter(Partner);
